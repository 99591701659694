import { FormEvent, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { Input, Button, CircularProgress } from "@mui/joy";
import { ROLES } from "../../utilities/StateEnums";

export function Login() {
    const navigate = useNavigate();
    const location = useLocation();
    let from = location.state?.from?.pathname || "/";

    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const { isLoggin, login, isLoginLoadin, isLoginError, userContext } = useUser();

    useEffect(() => {
        if(!isLoggin)
            return;

        if([ROLES.COMPRAS, ROLES.RESPONSABLE_COMP]
            .find(role => role === userContext.auth?.user?.tipoUsuario))
        {
            from = "/compras"; 
        }

        if([ROLES.AGENDAS]
            .find(role => role === userContext.auth?.user?.tipoUsuario))
        {
            from = "/agenda"; 
        }

        navigate(from, { replace: true});
    }, [isLoggin]);


    const handleLoginSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            setUsername("");
            setPassword("");
            login({username, password})
        } catch(e){
            console.log("Credenciales invalidas");
        }
    }

    return (
        <div className="flex justify-center mt-10">
            {isLoginLoadin && 
                <div className="flex items-center flex-col justify-center">
                    <strong className="text-gray-900 my-2">Validando Credenciales</strong>
                    <CircularProgress/>
                </div>
            }

            {!isLoginLoadin &&
            <div className="bg-gray-900 py-6 px-2 rounded-lg ">
            <div>
                <img 
                    className="w-72"
                    src="/logo_regiodental.png" 
                    alt="Logo Regiodental"/>

                <h1 className="text-slate-400 font-bold text-center text-xl">Inicio de Sesión</h1>
            </div>
            <form onSubmit={handleLoginSubmit} className="flex items-center flex-col justify-center ">
                <Input 
                    sx={(theme) => ({
                        "width": "90%"
                    })}
                        size="sm" 
                       className="my-2 w-auto"
                       type="text"
                       color="neutral"
                       variant="soft"
                       placeholder="Usuario"
                       value={username}
                       name="username"
                       onChange={(event) => setUsername(event.target.value)}/>
                <Input 
                    sx={(theme) => ({
                        "width": "90%"
                    })}
                        size="sm" 
                       className="my-2"
                       color="neutral"
                       variant="soft"
                       type="password"
                       placeholder="Contraseña"
                       value={password}
                       name="password"
                       onChange={(event) => setPassword(event.target.value)}/>
                <Button className="w-1/2" size="sm" color="neutral" variant="soft" type="submit">Iniciar Sesión</Button>
            </form>
            {isLoginError && 
                <strong className="text-gray-400">Credenciales invalidas</strong>
            }
            </div>

            }
        </div>
    );
}