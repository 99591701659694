import { MetricType } from "../types/metrics";

export enum Estados {
    Abierto = "Abierto",
    Proceso = "En Proceso",
    PendienteCompra = "Pendiente de compra",
    Terminado = "Terminado",
    Cancelado = "Cancelado",
    Vencido = "Vencido"
}

export enum Prioridad {
    Baja = "Baja",
    Media = "Media",
    Alta = "Alta",
    Urgente = "Urgente"
}

export enum Urgencia {
    Inmediata = "Menos de 4 horas",
    Dia = "Menos de 24 horas",
    TresDias = "24-72 horas",
    MasDias = "+72 horas"
}

export enum EstadosSolicitud {
    Abierto = "Abierto",
    Aprobado = "Aprobado",
    Proceso = "En Proceso",
    Terminado = "Terminado",
    Vencido = "Vencido",
    Cancelado = "Cancelado",
    Rechazado = "Rechazado",
}

export enum EstadosMantenimiento {
    Espera = "En Espera",
    Vencido = "Vencido"
}

export enum ComentarioTypes {
    TICKETS = "Ticket",
    SOLICITUD = "Solicitud"
}

export enum ROLES {
  ADMIN = "Admin",
  GENERAL = "General",
  MANTENIMIENTO = "Mantenimiento",
  COMPRAS = "Compras",
  RESPONSABLE_MAN = "Responsable-Mantenimiento",
  RESPONSABLE_COMP = "Responsable-Compras",
  AGENDAS = "Agendas",
}

export const GRAPHIC_COLORS = {
    DefaultColor: {
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
    },
    VencidosColor: {
        backgroundColor: 'rgba(255, 165, 0, 0.6)',
        borderColor: 'rgba(255, 165, 0, 1)',
    },
    NoVencidosColor: {
        backgroundColor: 'rgb(75, 192, 192, 0.6)',
        borderColor: 'rgb(75, 192, 192, 1)',
    }
}

export const METRICS_TITLE = {
    Compras: {
        NoVencidos: "Solicitudes No Vencidas",
        Vencidos: "Solicitudes Vencidas"
    },
    Ticket: {
        NoVencidos: "Ticket No Vencidos",
        Vencidos: "Ticket Vencidos"
    }
}

export const TypeGeneralId = [3, 11];

export const estadosList = Object.values(Estados);
export const prioridadList = Object.values(Prioridad);
export const urgenciaList = Object.values(Urgencia);
export const estadoMantenimiento = Object.values(EstadosMantenimiento);

export const especialidades = [
    {
        id: 1,
        nombre: "Cirugia Oral"
    },
    {
        id: 2,
        nombre: "Rehabilitación"
    },
    {
        id: 4,
        nombre: "Endodoncia"
    },
    {
        id: 5,
        nombre: "Odontopediatria"
    },
    {
        id: 6,
        nombre: "Ortodoncia"
    },
];

export const tipoDoctor = [
    "general",
    "especialista",
    "responsable",
];